.bio-container {
    background-color: black;
  }
  
  .bio-header {
  padding: .75em;
  margin-top: .75em;
  font-size: 2em;
  color: white;
  font-weight: bold;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
  }
  
  .bio-detail {
    padding: 1em 2em 2em 2em;
    margin-bottom: 2em;
    font-size: 1em;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  