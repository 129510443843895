.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  width: 100%;
  height: auto;
}

.navlink {
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 0 4em 0 4em;
  text-decoration: none;
}

@media (max-device-width : 480px) {
  .navlink {
    padding: 0 2em 0 2em;
  }
}

.navlink:visited {
  text-decoration: none;
}
