html { 
  text-align: center;
  background: url("./images/emptyStageCropped.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@font-face {
  font-family: "Back Issues";
  src: local("BackIssuesBB_reg"),
    url("./fonts/BackIssuesBB_reg.otf") format("opentype");
}

@media (max-device-width : 480px) {
  .video {
    height: 300px;
    width: 400px;
  }
}

button {
  background: none !important;
  color: lightgray;
  font-weight: bold;
  border: none;
  padding: 1em 6em 1em 6em !important;
  cursor: pointer;
}

.navigation {
  background-color: black;
  height: 3rem;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video {
  margin-top: 2em;
  width: '750';
  height: '500';
}
/* .dandalsGradPartyPolaroid {
  height: 40em;
  margin-top: 2em;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */
/* 
.container {
  background: url('./images/emptyStageCropped.jpeg');  
	height: 100vh;
	background-repeat: repeat;
	position: relative; /* use this if optional inner container is used */
/* } */

/* @media (max-device-width: 480px) {
  .container {
    height: 100vh;
  }
} */

.background:after {
	background-image: linear-gradient(45deg, #666 25%, transparent 25%), 
        linear-gradient(-45deg, #666 25%, transparent 25%), 
        linear-gradient(45deg, transparent 75%, #666 75%), 
        linear-gradient(-45deg, transparent 75%, #666 75%);
	background-size: 3px 3px;
	background-position: 0 0, 1px 0, 1px -1px, 0px 1px;
	padding-top: 62.5%;
	display: block;
	content: '';
  height: 100vh;
}
