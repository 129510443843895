.live-details {
    background-color: black;
    color: white;
}

.gig {
    margin: 1em;
    padding-top: 1em;
    padding-bottom: 1.5em;
}

.gig-header{
    font-weight:bold;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.gig-details {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}