.instagram {
  font-family: 'Back Issues';
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  font-size: 40px;
  color: white;
  text-shadow: -2px 2px 0 #000, 2px 2px 0 #000, 2px -2px 0 #000,
  -2px -2px 0 #000;
}

.instagram-logo {
  height: 7em;
  width: 7em;
  margin-bottom: -53px;
}
