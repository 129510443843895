.dandals-polaroid {
    height: 30em;
    padding: 2em;
}

.contact-container {
    background-color: black;
    color: white;
    height: 7em;
}

.contact-details {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 1em;
    padding-top: 1em;
    padding-bottom: 8px;
}